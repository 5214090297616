<template>
	<div
		class="support-text"
		:class="[size, state, { disabled }, { dark }]"
	>
		<div
			v-if="state"
			class="icon-wrapper"
		>
			<LazyTnIcon
				:name="iconName"
				:size="iconSize"
			/>
		</div>
		<slot>{{ text }}</slot>
	</div>
</template>

<script>
import sizes from "./definitions/sizes";

/**
 * TnSupportText is a reusable block to give simple visual feedback of state when validating forms and other user input
 * fields. It is reused to stay consistent in design between these components;
 *    - TnCheckbox
 *    - TnRadiobutton
 *    - TnTextField
 *    - TnTextArea
 * @displayName TnSupportText
 */
export default defineComponent({
	name: "TnSupportText",

	props: {
		/**
		 * Size of the support-text block
		 * @values s, m, l
		 */
		size: {
			type: String,
			default: "m",
			validator: (value) => {
				return sizes.includes(value.toLowerCase());
			},
		},
		/**
		 * Support or hint text to display
		 */
		text: {
			type: String,
		},
		/**
		 * Sets the state to *disabled* (includes icon)
		 */
		disabled: {
			type: Boolean,
			default: false,
		},
		/**
		 * Sets the state to *critical* (includes icon)
		 */
		critical: {
			type: Boolean,
			default: false,
		},
		/**
		 * Sets the state to *warning* (includes icon)
		 */
		warning: {
			type: Boolean,
			default: false,
		},
		/**
		 * Sets the state to *success* (includes icon)
		 */
		success: {
			type: Boolean,
			default: false,
		},
		/**
		 * Sets the state to *general/info* (includes icon)
		 */
		info: {
			type: Boolean,
			default: false,
		},
		/**
		 * Dark theming of component (for use on dark backgrounds)
		 */
		dark: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			iconMapping: {
				critical: "critical-filled",
				warning: "warning-circle-filled",
				success: "success-circle-filled",
				info: "info-circle-filled",
				disabled: "info-circle-filled",
			},
		};
	},

	computed: {
		state() {
			if (this.disabled) return "disabled";
			if (this.critical) return "critical";
			if (this.warning) return "warning";
			if (this.success) return "success";
			if (this.info) return "info";
			return null;
		},
		iconName() {
			if (!this.state?.disabled) {
				return this.iconMapping[this.state];
			}
		},
		iconSize() {
			switch (this.size) {
				case "l":
					return "m";
				default:
					return "s";
			}
		},
	},
});
</script>

<style lang="scss">
@use "@/assets/typography/scss/placeholders";
@use "@/assets/scss/variables" as variables;

.support-text {
	display: flex;
	align-items: center;
	font-family: variables.$font-family-telenor-ui;
	color: variables.$color-neutrals-600-shade;

	@extend %font-text-xs;

	.icon-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 4px;
	}

	&.s {
		@extend %font-text-2xs;
	}

	&.m {
		@extend %font-text-xs;
	}

	&.l {
		@extend %font-text-s;
	}

	&.m,
	&.l {
		.icon-wrapper {
			margin-top: 2px;
		}
	}

	&.disabled {
		color: variables.$color-neutrals-400-tint;

		.background {
			fill: variables.$color-neutrals-400-tint;
		}
	}

	&.dark:not(.disabled) {
		color: variables.$color-neutrals-300-tint;
	}
}
</style>
